import "./Footer.css";

const Footer = () => (
  <footer className="footer">
    <a href="https://xkcd.com/705/" className="link footer__link">
      What do you mean the server is down?
    </a>
  </footer>
);

export default Footer;
