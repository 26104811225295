import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ChatBubbleIcon from "@material-ui/icons/AcUnitOutlined";
import { about } from "../../portfolio";
import "./About.css";

const About = () => {
  const { name, role, description, resume, social } = about;

  return (
    <div className="about center">
      {name && (
        <h1>
          Hello there, I&apos;m <span className="about__name">Darren.</span>
        </h1>
      )}

      {role && <h2 className="about__role">tldr&#59; I code.</h2>}
      <p className="about__desc">{description && description}</p>

      <div className="about__contact center">
        {resume && (
          <a href={resume}>
            <span type="button" className="btn btn--outline">
              Resume
            </span>
          </a>
        )}

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label="github"
                className="link link--icon"
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label="linkedin"
                className="link link--icon"
              >
                <LinkedInIcon />
              </a>
            )}
            {social.discord && (
              <a
                href={social.discord}
                aria-label="discord"
                className="link link--icon"
              >
                <ChatBubbleIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default About;

// console log here brah idk okay yeah seems fine okay then alright

// Apprihend the message from the log, this continue to accours cause of the bysector and it's thingy doing things/!

// if the bysector is any prepend to the apprihended
