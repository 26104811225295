const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: "https://darrennathanael.com",
  title: "DarrenOfficial",
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name: "Darren Nathanael",
  role: "Human",
  description:
    "I am a Linux user. I have a passion for automating unnecessarily boring daily tasks and programming in general; I also like cookies! 🍪",
  // resume: 'https://example.com',
  social: {
    linkedin: "https://www.linkedin.com/in/darren-nathanael/",
    github: "https://github.com/darrenofficial",
    discord: "https://discord.com/users/508296903960821771",
  },
};

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: "Melonhause",
    description: "A survival minecraft server, thats running on 1.17.1",
    stack: ["Java", "Maven", "Kotlin"],
    sourceCode: "https://discord.gg/BYk9gsMm7w",
    livePreview: "https://melonhause.com/",
  },
  {
    name: "Cynathans",
    description: "Cynathans is a multipurpose discord bot.",
    stack: ["JavaScript", "Html", "React"],
    sourceCode: "https://github.com/cynathans",
    livePreview: "https://cynathans.com/",
  },
  {
    name: "Lavalink Host",
    description:
      "Lavalink is a standalone audio sending node based on Lavaplayer and JDA-Audio.",
    stack: ["Java", "Maven", "HaProxy", "TunnelBroker"],
    // sourceCode: 'https://github.com/cynathans',
    livePreview: "https://lavalink-list.darrennathanael.com/",
  },
  {
    name: "Nathan",
    description:
      "A Music bot that plays music from all music source, Apple Deezer Spotify Youtube Soundcloud and such.",
    stack: ["Java", "JS", "Docker", "TunnelBroker"],
    // sourceCode: 'https://github.com/cynathans',
    livePreview:
      "https://discord.com/oauth2/authorize?client_id=711323381252751480&permissions=45477193&scope=bot%20applications.commands",
  },
  {
    name: "Discord MusicBot",
    description:
      "An advanced discord music bot, supports Spotify, Soundcloud, YouTube with Shuffling, Volume Control and Web Dashboard with Slash Commands support!",
    stack: ["JavaScript", "DiscordAPI", "ExpressJS"],
    sourceCode: "https://github.com/SudhanPlayz/Discord-MusicBot/",
    livePreview: "https://discordmusicbot.darrennathanael.com",
  },
  {
    name: "API",
    description: "Just your avarage api | has radio and many more soon",
    stack: ["Kubernetes", "JS", "PHP", "Laravel"],
    // sourceCode: 'https://github.com/cynathans',
    livePreview: "https://api.darrennathanael.com/",
  },

  // {
  //   name: 'PlaceHolder',
  //   description:
  //     'Placeholder.',
  //   stack: ['PL', 'JS', 'Docker', "TunnelBroker"],
  //   // sourceCode: 'https://github.com/cynathans',
  //   livePreview: 'https://lavalink.darrennathanael.com/',
  // },
];

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  "Java",
  "JavaScript",
  "TypeScript",
  "CI/CD",
  "Docker",
  "Heroku",
  "Linux",
  "Nginx",
  "Apache",
  "AWS",
  "Git",
  "Maven",
  "Kubernetes",
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: "me@darrennathanael.com",
};

export { header, about, projects, skills, contact };
